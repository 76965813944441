import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=039f8974&scoped=true&"
import script from "./OrderDetail.vue?vue&type=script&lang=js&"
export * from "./OrderDetail.vue?vue&type=script&lang=js&"
import style0 from "./OrderDetail.vue?vue&type=style&index=0&id=039f8974&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039f8974",
  null
  
)

export default component.exports